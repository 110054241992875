import styled from "styled-components"
// import theme from "../../../../../../../../res/theme.js"

export const Form = styled.form`
  margin-top: 1rem;
`

export const OptionsContainer = styled.div`
  & > div {
    display: block;
    margin-top: 1rem;
  }
`
